#appin {
    overflow-y: scroll;
}


.home-page {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-img {
    width: fit-content;
}

.logo-text {
    width: fit-content;
}

.logo-container h3 {
    margin: 0;
    color: white;
    font-weight: 600;
    font-size: 20px;
}

.continue-content {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 20px;
    text-decoration: none;
    color: white;
    font-size: 22px;
    font-weight: 600;
}

.continue-icon {
    padding-left: 5px;
}

.auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.auth-container h3 {
    margin: 0px;
}

.policy-text {
    width: 315px;
}

.profile-bg {
    overflow: hidden;
}

.profile-bg img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.registry-image-background {
    position: relative;
    width: 100%;
}

.ad-image {
    position: absolute;
    width: 100%;
}
.registry-image-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(65, 105, 225, 0.5);
  z-index: 1;
}

.card-container {
    position: absolute;
    display: flex;
    align-self: center;
    flex-direction: column;
    padding: 20px;
    z-index: 1;
    background-color: white;
    border-radius: 10px;
}

.container {
    padding-left: 20px;
    padding-right: 20px;
    width: 100vw;
}

.personal-detail-stepper {
    width: 100%;
}

.personal-detail-stepper .stepper-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stepper-content {
    display: flex;
    justify-content: space-evenly;
}

.personal-detail-stepper .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31px;
    height: 31px;
    border: solid 2px #4169E1;
    border-radius: 50%;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    color: #4169E1;
}

.stepper-line {
    width: 50%;
    height: 2px;
    margin-top: 16.25px;
    background-color: #4169E1;
}

.personal-detail-stepper .text {
    margin-top: 15px;
    font-weight: 500;
    color: #A0B4F0;
}

.personal-detail-stepper .active .text {
    color: #4169E1;
}

.personal-detail-stepper .active .circle  {
    color: white;
    background-color: #4169E1;
}

.personal-detail-nav {
    width: 100%;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    color: #4169E1;
    font-weight: 600;
}

.personal-detail-nav .back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}

.personal-detail-nav .space {
    width: 42px;
}

.step-1 {
    width: 100%;
}

.personal-detail-not {
    padding-left: 3px;
    font-size: 10px;
    font-weight: 400;
}

.profile-setting {
    display: flex;
    flex-direction: column;
}

.profile-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile-setting .navbar a {
    display: flex;
    align-items: center;
}

.profile-setting .container {
    margin-left: auto;
    margin-right: auto;
}

.profile-detail .navbar a {
    display: flex;
    align-items: center;
}

.profile-detail .navbar a {
    padding-left: 15px;
    padding-right: 15px;
}

.profile-setting .navbar a {
    padding-left: 15px;
    padding-right: 15px;
}

.profile-base-info {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 100%;
    background: white;
}

.profile-base-info .title {
    padding-left: 30px;
}

.profile-setting .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    background-color: #fff;
    box-shadow: 0px 10px 10px 0px #dedfdf;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.profile-setting .profile-image-container .profile-images div {
    width: 30%;
    height: 0px;
    position: relative;
    padding-top: 35%;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.profile-setting .profile-image-container .profile-images div img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 50%;
}

.profile-detail .navbar {
    position: fixed;
    top: 0;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    background-color: #fff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.profile-base-info-contant {
    padding-left: 10px;
    padding-right: 10px;
}

.profile-base-info-contant .outline-textedit {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    min-width: auto;
}

.profile-images {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 16px;
}

.profile-image-container {
    margin-left: 10px;
    margin-right: 10px;
}

@media screen and (min-width: 600px) {
    .profile-image-container {
        margin-left: auto;
        margin-right: auto;
    }
}

.profile-images img {
    padding-bottom: 10px;
    width: 30%;
}

.profile-image-not {
    padding-top: 20px;
    text-align: center;
    font-size: 12px;
}

.self-introduction-container {
    width: 100vw;
    /* margin-left: auto;
    margin-right: auto; */
    align-self: center;
    background-color: white;
}

.self-introduction {
    height: 300px;
    width: -webkit-fill-available;
    min-width: -webkit-fill-available;
    max-width: -webkit-fill-available;
    max-height: 300px;
    min-height: 300px;
    margin: 10px 10px;
    border-radius: 0;
    border: none;
    outline: none;
}

.camera-button {
    position: relative;
    bottom: 110px;
    padding: 0px;
    background-color: transparent;
    border: none;
    outline: none;
    border-radius: 50%;
}

.image-selector {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.image-selector-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 280px;
    border: 10px solid white;
    border-radius: 50%;
    overflow: hidden;
    background-color: #F9FDFC;
    box-shadow: 0px 0px 10px 10px #f1f5f5;
}

.image-selector-content img {
}

.bottom-navbar-container {
    display: flex;
    justify-content: center;
}

.bottom-navbar {
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: white;
    z-index: 999999;
}

.bottom-navbar a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 50px;
}

.nav-title {
    font-size: 9px;
    color: #323232;
    font-weight: 500;
}

.profile-top-card {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    background: white;
    padding-top: 20px;
}

.profile-top-card .user-name {
    font-weight: 600;
}

.profile-top-card .left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile-img {
    display: flex;
    align-items: baseline;
    justify-content: center;
    height: 90px;
    margin-left: 20px;
    margin-right: 20px;
    width: 90px;
    overflow: hidden;
    border-radius: 50%;
}

.profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-not {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.profile-not a {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
}

.billing-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
}

.billing-container div {
    text-align: center;
}

.unregistered-text {
    font-size: 10px;
    padding: 5px;
    background-color: #A0B4F0;
}

.billing-link {
    align-self: center;
    font-size: 12px;
    text-decoration: none;
    color: black;
    font-weight: 500;
}

.scam-not {
    width: 100%;
    align-self: center;
}

.scam-not .card {
    width: -webkit-fill-available;
    justify-content: space-between;
    padding: 10px;
    margin: 0 15px;
    background-color: white;
    color: black;
    font-size: 11px;
    text-decoration: none;
}

.scam-not>div {
    width: 100%;
    justify-content: space-between;
    font-size: 11px;
}

.setting-container {
    width: 95%;
    padding: 0 2.5%;
    align-self: center;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.setting-container .card {
    justify-content: space-between;
    padding: 10px 0;
    margin: 5px;
    color: black;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
}

.secret-state {
    padding-right: 10px;
    color: #666666;
}

.recommandation-container {
    display: flex;
    justify-content: center;
    position: relative;
}

.main-image-container {
    width: 100%;
    height: 100%;
    align-self: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    overflow: hidden;
}

.main-image-item {
    display: flex;
    align-items: center;
    height: calc(100vh - 60px);
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  z-index: 1;
}

.main-image-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.user-context {
    position: absolute;
    bottom: 0;
    color: white;
}

.user-context .user-name {
    padding-left: 20px;
    font-size: 30px;
}

.user-context .base-info {
    padding-left: 20px;
    font-size: 18px;
}

.user-context .main-content {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
}

.user-action {
    display: flex;
    justify-content: space-around;
}

.user-action button, .user-info a {
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    border: none;
}

.user-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.user-info div {
    padding-right: 10px;
}

.user-info a {
    width: 31px;
    height: 31px;
    z-index: 99;
}

.talk-container, .matchin-container {
    width: 100%;
    min-height: calc(100vh - 62px);
    padding-bottom: 62px;
}

.main-container {
    width: 100%;
    min-height: calc(100vh - 62px);
    padding-bottom: 62px;
}

.main-container .image-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
}

.main-container {
    background-color: white;
}

.main-container .user-info {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-bottom: 20px;
}

.main-container .user-info .user-name {
    font-size: 18px;
    color: white;
}

.main-container .user-info .user-detail {
    font-size: 12px;
    color: white;
}

.list-image-container {
    width: 48%;
    position: relative;
    padding-top: 70%;
    margin-bottom: 4%;
}


.list-image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

.list-image-container img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.user-list-item {
    display: flex;
    padding: 10px 20px;
}

.user-list-item img {
    width: 84px;
    height: 84px;
    object-fit: cover;
    border-radius: 50%;
}

.user-list-item .item-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 10px;
    padding-top: 10px;
}

.user-list-item .item-info .item-detail {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 10px;
}

.user-list-item .item-info .item-name {
    font-size: 13x;
}
.talk-not a, .matchin-not a {
    color: #4169E1;
    text-decoration: none;
}

.talk-not, .matchin-not {
    padding: 10px 0px;
    font-size: 11px;
    background-color: white;
    text-align: center;
}

.matchin-container .item-info {
    display: flex;
    justify-content: center;
    padding-top: 0;
}

.matchin-container .user-list-item {
    align-items: center;
}

.matchin-message {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
    color: black;
}

.detail-image-item {
    position: relative;
    width: 100%;
    padding-top: 80%;
}

.detail-image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
}

.detail-name {
    font-size: 24px;
    font-weight: 500;
}

.profile-detail .detail-info {
    padding-top: 54px;
}

.detail-info .bio {
    padding: 25px 20px;
    border-top: solid 1px #4169E1;
    border-bottom: solid 1px #4169E1;
    font-size: 14;
}

.baseinfo-title {
    font-size: 13.82px;
    color: #BFBFBF;
}

.baseinfo-value {
    font-size: 11px;
    color: #4169E1;
}

.baseinfo-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
}

.open-modal {
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    border: none;
}

.modal-action {
    padding: 10px 0;
    font-size: 18px;
    font-weight: 600;
    color: #4169E1;
    background-color: white;
    border: none;
    border-bottom: 1px solid #C4C4C4;
    text-decoration: none;
    text-align: center;
}

.modal-action.report {
    color: #DD3562;
    text-decoration: none;
    text-align: center;
}

.detail-action-modal {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.detail-action-modal .modal-container {
    border-radius: 10px !important;
    overflow: hidden;
    box-shadow: none !important;
}

.chat-elements button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    background-color: white;
    border: none;
    border-radius: 50%;
}

.chat-elements .select-file {
    margin-left: 15px;
    margin-right: 5px;
}

.chat-elements .send-message {
    margin-left: 5px;
    margin-right: 15px;
}

.edit-message-container {
    flex: 1;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 30px;
}

.chat-elements .edit-message {
    flex: 1;
    font-size: 14px;
    resize: none;
    min-height: 14px;
    max-height: 100px;
    width: 100px;
    overflow: hidden;
    outline: none;
    background-color: transparent;
    z-index: 5;
}

.chat-elements {
    /* position: relative; */
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.chat-send-container {
    position: sticky;
    bottom: 10px;
    width: inherit;
}

.message-date {
    text-align: center;
    font-size: 16px;
    color: #B8B8B8;
}

.message-time {
    font-size: 16px;
    color: #B8B8B8;
}

.message-content {
    display: flex;
    align-items: flex-end;
    max-width: 80%;
    width: fit-content;
}

.sent-message .message-text, .received-message .message-text {
    padding: 10px 7px;
    border-radius: 15px;
    font-size: 14px;
    word-wrap: break-word;
    max-width: inherit;
    overflow-wrap: anywhere;
}

.received-message {
    margin-right: auto;
}

.sent-message {
    margin-left: auto;
}

.received-message .message-text {
    background-color: #A0B4F0;
}

.sent-message .message-text {
    background-color: white;
}

.chat-main-container {
    height: calc(100vh - 96px - 1rem);
    overflow-y: scroll;
}

.edit-message-container .placeholder {
    position: absolute;
}

.main-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    height: 46px;
    background-color: #4169E1;
    color: white;
    border: none;
    font-size: 15px;
    border-radius: 8px;
    text-decoration: none;
}

.age-verification {
    width: 179px;
    height: 34px;
    background-color: #4169E1;
    color: white;
    border: none;
    border-radius: 8px;
    text-decoration: none;
}

.age-confirmation {
    height: calc(100vh - 54px);
}

.age-check-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 50%;
}

.age-check-container img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
}

.plan-panel {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: white;
    text-decoration: none;
    color: black;
}

.left-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.budget-card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 121px;
    border-radius: 10px;
}

.right-card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 98px;
    width: 112px;
    border-radius: 10px;
}

.first-plan .right-card {
    height: 61px;
}

.status-card {
    height: 100px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.reporter-email {
    height: 42px;
    width: -webkit-fill-available;
    outline: none;
    border-radius: 10px;
    padding: 0 15px;
    font-size: 18px;
}

.attachment-file {
    height: 42px;
    width: -webkit-fill-available;
    outline: none;
    padding: 0 15px;
}

.report-content {
    height: 150px;
    width: -webkit-fill-available;
    outline: none;
    resize: none;
    padding: 10px 15px;
}

.send-button {
    border: none;
    border-radius: 10px;
    width: -webkit-fill-available;
    height: 46px;
}

.notification-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    border-bottom: 1px #C4C4C4 solid;
    text-decoration: none;
    color: black;
}

.various-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 43px;
    border-bottom: 1px #C4C4C4 solid;
    text-decoration: none;
    color: black;
}

.notificaiton-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    border-bottom: 1px #C4C4C4 solid;
    text-decoration: none;
    color: black;
}

.join-platinum {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    border: none;
    border-radius: 20px;
    background-color: white;
    -webkit-box-shadow: -1px 6px 6px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: -1px 6px 6px 1px rgba(34, 60, 80, 0.2);
    box-shadow: -1px 6px 6px 1px rgba(34, 60, 80, 0.2);
}

.withdrawn-image-container {
    position: relative;
    padding: 0 4rem;
}

.withdrawn-image-container img {
    width: 100%;
    object-fit: cover;
}

.secret-on, .secret-off {
    width: 156px;
    height: 26px;
    background-color: #D9D9D9;
    text-decoration: none;
    color: black;
}

.secret-on.active, .secret-off.active {
    background-color: #A0B4F0;
}

.secret-on.active .radio, .secret-off.active .radio {
    border: 3px solid #4169E1;
    width: 6px;
    height: 6px;
}

.secret-on .radio, .secret-off .radio {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    margin-right: 5px;
}

.secret-on label, .secret-off label {
    flex: none;
    -webkit-flex: none;
}

.my-detail-image-item {
    padding-top: 100%;
}

.payment-detail {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.payment-detail div {
    width: 50%;
    padding-top: 1rem;
}

.accordion-icon {
    width: 14px;
    height: 14px;
    border: 1px #4169E1 solid;
    border-radius: 50%;
    order: -1;
}

.accordion-icon.active {
    border: 5px #4169E1 solid;
    width: 7px;
    height: 7px;
}

.credit-card {
    padding: .5rem;
    margin: 20px;
    border: 1px solid #5C5C5C;
    border-radius: 5px;
}

.credit-paybutton {
    height: 34px;
    margin: 0 1.3rem;
    border: none;
    border-radius: 5px;
    background-color: #4169E1;
    color: white;
}

.bank-card {
    width: 100%;
}

.control-dots {
    position: absolute;
    top: 0px;
    height: fit-content;
}

@media screen and (min-width: 600px) {
    .detail-action-modal {
        width: 570px;
    }
}

@media screen and (min-width: 600px) {
    .container, .profile-setting .navbar, .profile-image-container, .self-introduction-container, .profile-base-info, .bottom-navbar,
    .main-image-container, .user-context, .main-container,
    .container-fluid, .talk-container, .matchin-container, .profile-detail, .profile-detail .navbar {
        width: 600px;
    }
}
@media screen and (min-width: 400px) {
    .bank-card {
        width: 340px;
    }
}


.admin-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 3rem;
}

.admin-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-table table {
    width: 100%;
    min-width: 4000px;
    --data-table-library_grid-template-columns: minmax(0px, 0.3fr) minmax(0px, 2fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) !important;
}

.admin-table-action {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.admin-table-action-left {
    width: 50%;
}

.admin-table-action-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-table-action-right button {
    width: 230px;
    margin-bottom: 10px;
}

.admin-button {
    background: green;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
}

.admin-table-csv {
    align-self: flex-start;
}

.admin-table-search {
    align-self: flex-start;
}

.admin-table-search input {
    height: 20px;
    margin-right: 10px;
    margin-top: 10px;
}

.admin-table-delete {
    align-self: flex-start;
    margin-top: 10px;
}
