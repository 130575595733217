body {
    background-color: #F5F5F5;
}


/* container */

.container-fluid {
    width: 100%;
}

.an-container {
    width: 100%;
}

.an-navbar {
    position: sticky;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    width: 100%;
    background-color: #F5F5F5;
    z-index: 1;
}

.an-navbar a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
}

.an-nav-right {
    display: flex;
    align-items: center;
}

.an-nav-right img {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    object-fit: cover;
}

.an-nav-right .title {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 500;
}

.an-navbar.br-grey {
    width: calc(100% - 2px);
}

@media screen and (min-width: 600px) {
    .an-container, .an-navbar {
        width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    .an-navbar.br-grey {
        width: 598px;
        margin-left: auto;
        margin-right: auto;
    }
}


button {
    cursor: pointer;
}

.default-button {
    text-decoration: none;
}

/* background */
.bg-gradient {
    background-image: linear-gradient(250deg, #89CFF0, #4169E1);
}

.bg-sky {
    background-color: #60ABE1;
}

.bg-purple {
    background-color: #6D41BF;
}

.bg-grey {
    background-color: #C4C4C4;
}

.bg-primary {
    background-color: #4169E1 !important;
}

.bg-translucent-blue {
    background-color: #4169E188;
}

.bg-white {
    background-color: white;
}

.bg-red {
    background-color: #D94628;
}


/* border */

.br-grey {
    border: #C4C4C4 solid 1px;
}

.br-primary {
    border: #4169E1 solid 1px !important;
}

.br-none {
    border: none;
    outline: none;
}

.br-radius {
    border-radius: 10px;
}


/* font-size */

.f-11 {
    font-size: 11px;
    font-weight: 400;
}

.f-7 {
    font-size: 7px;
    font-weight: 500;
}

.f-9 {
    font-size: 9px;
    font-weight: 400;
}

.f-8 {
    font-size: 8px;
    font-weight: 500;
}

.f-10 {
    font-size: 10px;
    font-weight: 400;
}

.f-12 {
    font-size: 12px;
    font-weight: 400;
}

.f-13 {
    font-size: 13px;
    font-weight: 400;
}
.f-14 {
    font-size: 14px;
    font-weight: 500;
}
.f-16 {
    font-size: 16px;
    font-weight: 700;
}
.f-20 {
    font-size: 24px;
    font-weight: 600;
}

.f-32 {
    font-size: 32px;
}

.f-bold {
    font-weight: bold;
}

/* font-color */
.fc-primary {
    color: #33196B;
}

.fc-main {
    color: #4169E1;
}

.fc-grey {
    color: #BFBFBF;
}

.fc-black {
    color: #000000;
}

.fc-white {
    color: #fff;
}

/* diplay */
.d-flex {
    display: flex;
    .dh-100 {
        height: 100vh;
    }
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.w-30 {
    width: 30%;
}

.w-70 {
    width: 70%;
}

.justify-center {
    justify-content: center;
}

.justify-space {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.align-baseline {
    align-items: baseline;
}

/* text */

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

/* padding */

.pt-05 {
    padding-top: 0.5rem !important;
}
.pt-1 {
    padding-top: 1rem !important;
}
.pt-1-5 {
    padding-top: 1.5rem !important;
}
.pt-1-8 {
    padding-top: 1.8rem !important;
}
.pt-2 {
    padding-top: 2rem !important;
}
.pt-2-5 {
    padding-top: 2.5rem !important;
    line-height: 1.5;
}
.pt-3 {
    padding-top: 3rem !important;
}
.pt-3-5 {
    padding-top: 3.5rem !important;
}
.pt-4 {
    padding-top: 4rem !important;
}
.pt-5 {
    padding-top: 5rem !important;
}
.pt-6 {
    padding-top: 6rem !important;
}


.pb-05 {
    padding-bottom: 0.5rem !important;
}
.pb-1 {
    padding-bottom: 1rem !important;
}
.pb-1-5 {
    padding-bottom: 1.5rem !important;
}
.pb-1-8 {
    padding-bottom: 1.8rem !important;
}
.pb-2 {
    padding-bottom: 2rem !important;
}
.pb-2-5 {
    padding-bottom: 2.5rem !important;
    line-height: 1.5;
}
.pb-3 {
    padding-bottom: 3rem !important;
}
.pb-3-5 {
    padding-bottom: 3.5rem !important;
}
.pb-4 {
    padding-bottom: 4rem !important;
}
.pb-5 {
    padding-bottom: 5rem !important;
}
.pb-6 {
    padding-bottom: 6rem !important;
}


.pl-1 {
    padding-left: 1rem !important;
}
.pl-05 {
    padding-left: .5rem !important;
}
.pl-2 {
    padding-left: 2rem !important;
}
.pl-3 {
    padding-left: 3rem !important;
}



.pr-05 {
    padding-right: .5rem !important;
}
.pr-1 {
    padding-right: 1rem !important;
}

.pr-2 {
    padding-right: 2rem !important;
}
.pr-3 {
    padding-right: 3rem !important;
}

/* display */
.dh-100 {
    height: 100vh;
}


/* margin */

.mb-1 {
    margin-bottom: 1rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}
.mb-2 {
    margin-bottom: 2rem !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.ml-05 {
    margin-left: .5rem !important;
}
.ml-1 {
    margin-left: 1rem !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.ml-1-5 {
    margin-left: 1.5rem !important;
}

.ml {
    margin-left: auto;
}

.mr {
    margin-right: auto;
}

/* height */
.h-inherit {
    min-height: inherit;
}
