html {
  overflow-y: scroll,
}

body {
  margin: 0;
  font-family: 'Lexend';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
  background-color: #F5F5F5;
}

#fullScreenMode {
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
  background-color: transparent;
  border: none;
  z-index: 99999999999;
}