
.outline-textedit {
    display: flex;
    align-items: center;
    height: 45px;
    min-width: 300px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 25px;
    border: 2px solid #436CE1;
}

.outline-textedit input {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    border: none;
    font-size: 14px;
    background-color: #F5F5F5;
    outline: none;
}

.outline-textedit input:-internal-autofill-selected {
    background-color: white !important;
}

.link-text {
    color: #436CE1;
    text-decoration: none;
}

.primary-button {
    min-width: 310px;
    height: 45px;
    border: none;
    border-radius: 25px;
    color: white;
    font-size: 15px;
    font-weight: 600;
}

.text-spacing-2 {
    letter-spacing: -2px;
}

.anemone-input {
    display: flex;
    overflow: hidden;
    border: #A0B4F0 2px solid;
    height: 50px;
    width: calc(100% - 4px);
    border-radius: 30px;
}

.anemone-select {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    width: calc(100% - 4px);
    border-radius: 30px;
    border: #A0B4F0 2px solid;
}

.anemone-select>div {
    width: 95%;
    align-self: center;
    outline: none !important;
}

.anemone-select input {
    color: #436CE1;
}

.anemone-select>div>div>div>div {
    color: #A0B4F0 !important;
}

.anemone-select>div>div {
    border: none;
    background-color: #F5F5F5;
    box-shadow: none;
    outline: none !important;
}

.anemone-input.active input {
    color:#436CE1;
}

.anemone-input.active {
    border: #436CE1 2px solid;
}

.anemone-input input {
    width: calc(100% - 8px);
    padding-right: 20px;
    padding-left: 20px;
    font-size: 18px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #A0B4F0;
}

.input-title, .select-title {
    position: relative;
    top: 10px;
    left: 20px;
    padding-left: 10px;
    padding-right: 20px;
    font-size: 12px;
    font-weight: 400;
    color: #A0B4F0;
    background-color: #F5F5F5;
}

.outline-button {
    padding: 5px 20px;
    border-radius: 25px;
    border: 1px solid #436CE1;
    text-decoration: none;
}

.billing-card {
    display: flex;
    width: 7rem;
    margin-left: 10px;
    margin-right: 10px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
}

.billing-card .title {
    padding-bottom: 15px;
}

.billing-card a {
    padding: 15px;
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    color: black;
}

.red-badge {
    background-color: #CC3263;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.btn {
    background-color: transparent;
    border: none;
    outline: none;
}

.image-select {
    display: none;
}